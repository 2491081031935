<template>
    <b-sidebar
        id="add-new-user-sidebar"
        :visible="isAddNewUserSidebarActive"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        right
        @hidden="resetForm"
        @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
    >
        <template #default="{ hide }">
            <!-- Header -->
            <div
                class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
            >
                <h5 class="mb-0">Registrar Nuevo Empleado</h5>

                <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="hide"
                />
            </div>

            <!-- BODY -->
            <validation-observer
                #default="{ handleSubmit }"
                ref="refFormObserver"
            >
                <!-- Form -->
                <b-form
                    class="p-2"
                    @submit.prevent="handleSubmit(onSubmit)"
                    @reset.prevent="resetForm"
                >
                    <validation-provider
                        #default="validationContext"
                        name="Sucursal"
                        rules="required"
                    >
                        <b-form-group label="Sucursal" label-for="sucursal_id">
                            <b-form-select
                                id="sucursal_id"
                                v-model="userData.sucursal_id"
                                :options="sucursales"
                                :state="getValidationState(validationContext)"
                                trim
                            >
                            </b-form-select>

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Numero de Empleado -->
                    <validation-provider
                        #default="validationContext"
                        name="Numero de empleado"
                        rules="required"
                    >
                        <b-form-group
                            label="Numero Empleado"
                            label-for="numero_empleado"
                        >
                            <b-form-input
                                id="numero_empleado"
                                v-model="userData.numero_empleado"
                                autofocus
                                type="number"
                                :state="getValidationState(validationContext)"
                                trim
                                placeholder="4"
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Full Name -->
                    <validation-provider
                        #default="validationContext"
                        name="Nombre Completo"
                        rules="required"
                    >
                        <b-form-group
                            label="Nombre Completo"
                            label-for="nombre_completo"
                        >
                            <b-form-input
                                id="nombre_completo"
                                v-model="userData.nombre_completo"
                                autofocus
                                :state="getValidationState(validationContext)"
                                trim
                                placeholder="Juan Perez Lopez"
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Email 
          <validation-provider
            #default="validationContext"
            name="RFC"
            rules=""
          >
            <b-form-group
              label="RFC"
              label-for="rfc"
              
            >
              <b-form-input
                id="rfc"
                v-model="userData.rfc"
                placeholder="opcional..."
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Telefono"
            rules=""
          >
            <b-form-group
              label="Telefono"
              label-for="telefono"
            >
              <b-form-input
                id="telefono"
                v-model="userData.telefono"
                placeholder="opcional..."
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

         <validation-provider
            #default="validationContext"
            name="Direccion"
            rules=""
          >
            <b-form-group
              label="Direccion"
              label-for="direccion"
            >
              <b-form-input
                id="direccion"
                v-model="userData.direccion"
                placeholder="opcional..."
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

         <validation-provider
            #default="validationContext"
            name="Centro de Trabajo"
            rules="required"
          >
            <b-form-group
              label="Centro de Trabajo"
              label-for="centro_costo_id"
            >
              <b-form-select
                id="centro_costo_id"
                v-model="userData.centro_costo_id"
                :options="sucursales"
                :state="getValidationState(validationContext)"
                trim>
              </b-form-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-2"
                            type="submit"
                        >
                            Registrar
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="button"
                            variant="outline-secondary"
                            @click="hide"
                        >
                            Cancelar
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>
import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "@/@fake-db/data/other/countries";
import store from "@/store";
import axios from "@axios";

export default {
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        vSelect,
        BFormSelect,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    model: {
        prop: "isAddNewUserSidebarActive",
        event: "update:is-add-new-user-sidebar-active",
    },
    props: {
        isAddNewUserSidebarActive: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            sucursales: [],
            required,
            alphaNum,
            email,
            countries,
        };
    },
    setup(props, { emit }) {
        const blankUserData = {
            sucursal_id: 1,
            numero_empleado: null,
            nombre_completo: null,
        };

        const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
        const resetuserData = () => {
            userData.value = JSON.parse(JSON.stringify(blankUserData));
        };

        const onSubmit = () => {
            store.dispatch("app-empleado/addUser", userData.value).then(() => {
                emit("refetch-data");
                emit("update:is-add-new-user-sidebar-active", false);
            });
        };

        const { refFormObserver, getValidationState, resetForm } =
            formValidation(resetuserData);

        return {
            userData,
            onSubmit,

            refFormObserver,
            getValidationState,
            resetForm,
        };
    },
    mounted() {
        this.getSucursales();
    },
    methods: {
        getSucursales() {
            axios
                .get("https://api.sushijimmysan.com/api/dragon/cat-sucursales")
                .then((response) => {
                    this.sucursales = response.data.data.map((item) => {
                        return {
                            value: item.Codigo,
                            text: item.Nombre,
                        };
                    });
                });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
    .vs__dropdown-menu {
        max-height: 200px !important;
    }
}
</style>
